import React from 'react';
import {Link, Outlet} from 'react-router-dom';
import "../css/Registration.css";
import {versionInfo} from '../versionInfo';

function Layout() {
    return (
        <div className="container">

            {/* Main Content Area */}
            <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                <main className="col-12">
                    <div className="row my-4">
                        {/* <div className="col"> */}
                            <Outlet/>
                        {/* </div> */}
                    </div>
                </main>

                {/* Footer */}
                <footer className="pt-3 mt-5 border-top">
                    <div className="row text-center text-md-start">
                        <div className="col-12 col-md-3 d-flex justify-content-center align-items-center mb-3">
                            <img src="/bevital_logo_simple.png" alt="Be Vital Health Logo" width="141" height="50"/>
                        </div>
                        <div className="col-12 col-md-3">
                            <h5>Be Vital Health</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2">
                                    <a href="https://www.bevitalhealth.com" className="nav-link p-0 text-muted"
                                       target="_self">
                                        Home</a>
                                </li>
                                <li className="nav-item mb-2">
                                    <Link className="nav-link p-0 text-muted" to="/privacy-policy">Privacy Policy</Link>
                                </li>
                                <li className="nav-item mb-2">
                                    <Link className="nav-link p-0 text-muted" to="/terms-of-use">Terms of Use</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-3">
                            <h5>About</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2">
                                    <a href="https://www.bevitalhealth.com/about" className="nav-link p-0 text-muted"
                                       target="_self">
                                        About Us</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-3">
                            <h5>Contact</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2">
                                    <a href="https://www.bevitalhealth.com/contact" className="nav-link p-0 text-muted"
                                       target="_self">
                                        Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="pt-4 my-4 border-top text-center">
                        <p className="text-muted">
                            <small>
                                Information on this website is for informational purposes only and not intended to
                                diagnose, treat, cure or prevent disease or to render medical advice.
                                Information on this website is not intended to replace management by a health care
                                provider, please work with a trained health care provider. Statement made on this
                                website have not been evaluated by the Food and Drug Administration.
                            </small>
                        </p>
                        <p className="text-muted small">
                            Build Commit: <span id="commitHash"> {versionInfo.commitHash}</span> |
                            Date: <span id="branchName">{versionInfo.buildDate}</span> |
                            Branch: <span id="buildDate">{versionInfo.branchName}</span> |
                            Tag: <span id="tag">{versionInfo.tag}</span>
                        </p>
                        <p className="text-muted">© 2024 Be Vital Health, Inc.</p>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Layout;
