import {PatternFormat} from 'react-number-format';
import React from "react";

function Contact({formData, handleChange}) {
    return (
        <div>
            {/* First Name and Last Name in one row */}
            <div className="row g-3">
                <div className="col-md-6">
                    <label htmlFor="firstName" className="form-label">First Name *</label>
                    <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                    <div className="invalid-feedback">First Name is required</div>
                </div>
                <div className="col-md-6">
                    <label htmlFor="lastName" className="form-label">Last Name *</label>
                    <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                    <div className="invalid-feedback">Last Name is required</div>
                </div>
            </div>

            {/* Address fields in one row */}
            <div className="row g-3">
                <div className="col-md-12">
                    <label htmlFor="address1" className="form-label">Address *</label>
                    <input
                        type="text"
                        className="form-control"
                        id="address1"
                        name="address1"
                        value={formData.address1}
                        onChange={handleChange}
                        required
                    />
                    <div className="invalid-feedback">Address is required</div>
                </div>
                <div className="col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        id="address2"
                        name="address2"
                        value={formData.address2}
                        onChange={handleChange}
                    />
                </div>
            </div>

            {/* City, State, and Zip Code in one row */}
            <div className="row g-3">
                <div className="col-md-4">
                    <label htmlFor="city" className="form-label">City *</label>
                    <input
                        type="text"
                        className="form-control"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        required
                    />
                    <div className="invalid-feedback">City is required</div>
                </div>
                <div className="col-md-4">
                    <label htmlFor="state" className="form-label">State *</label>
                    <select
                        id="state"
                        className="form-select"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Choose...</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                    </select>
                    <div className="invalid-feedback">State is required</div>
                </div>
                <div className="col-md-4">
                    <label htmlFor="zipCode" className="form-label">Zip Code *</label>
                    <input
                        type="text"
                        className="form-control"
                        id="zipCode"
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleChange}
                        required
                    />
                    <div className="invalid-feedback">Zip Code is required</div>
                </div>
            </div>

            {/* Email and Phone Number in one row */}
            <div className="row g-3">
                <div className="col-md-6">
                    <label htmlFor="emailAddress" className="form-label">Email Address *</label>
                    <input
                        type="email"
                        className="form-control"
                        id="emailAddress"
                        name="emailAddress"
                        value={formData.emailAddress}
                        onChange={handleChange}
                        required
                    />
                    <div className="invalid-feedback">Email Address is required</div>
                </div>
                <div className="col-md-6">
                    <label htmlFor="phoneNumber" className="form-label">Phone Number *</label>
                    <PatternFormat
                        format="(###) ###-####"
                        mask="_"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                    />
                    <div className="invalid-feedback">Phone Number is required</div>
                </div>
            </div>

            {/* Date of Birth and Gender in one row */}
            <div className="row g-3">
                <div className="col-md-6">
                    <label htmlFor="dateOfBirth" className="form-label">Date of Birth *</label>
                    <input
                        type="date"
                        className="form-control"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={formData.dateOfBirth}
                        onChange={handleChange}
                        required
                    />
                    <div className="invalid-feedback">Date of Birth is required</div>
                </div>
                <div className="col-md-6">
                    <label htmlFor="gender" className="form-label">Gender *</label>
                    <select
                        id="gender"
                        className="form-select"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Choose...</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                    <div className="invalid-feedback">Gender is required</div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
