import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CartContext } from '../components/CartContext';
import PatientForm from "./PatientForm";
import PaymentForm from "./PaymentForm";
import ThankYouPage from "./ThankYou";

function CheckoutForm() {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { promoCode } = useParams();

    const { cartItems } = useContext(CartContext);
   
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [step, setStep] = useState(1);
    const [patientInfo, setPatientInfo] = useState({
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        emailAddress: '',
        phoneNumber: '',
        dateOfBirth: '',
        gender: ''
    });

    const [paymentInfo, setPaymentInfo] = useState({
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: ''
    });

    const stepMessages = {
        1: 'Patient Information',
        2: 'Payment Details',
        3: ''
    };

    const [pageTitle, setPageTitle] = useState(stepMessages[step]);

    useEffect(() => {
        setPageTitle(stepMessages[step]);
        window.scrollTo(0, 0);
    }, [step, pathname]);

    const handleNext = useCallback(() => {
        setStep(prevStep => Math.min(prevStep + 1, 3));
    }, []);

    const handlePrevious = useCallback(() => {
        setStep(prevStep => Math.max(prevStep - 1, 1));
    }, []);

    return (
        <div className="container">
            {/* Row 1: Logo and Heading */}
            <div className="container">
                <div className="row mb-4 align-items-center justify-content-center">
                    <div className="col-12 col-md-3 text-center text-md-start mx-auto">
                        <img src="/bevital_logo_sm.png" alt="Logo" className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-5 text-center text-md-start mx-auto pt-3 pt-md-0">
                        <h1>{pageTitle}</h1>
                    </div>
                </div>
            </div>

            {/* Payment Options */}
            <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                    <div>
                        {step === 1 &&
                            <PatientForm patientInfo={patientInfo} setPatientInfo={setPatientInfo}
                                handlePrevious={handlePrevious} handleNext={handleNext} />}
                        {step === 2 &&
                            <PaymentForm patientInfo={patientInfo} paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo}
                                handlePrevious={handlePrevious} handleNext={handleNext} />}
                        {step === 3 && <ThankYouPage patientInfo={patientInfo} paymentInfo={paymentInfo} />}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CheckoutForm;
