import React, { useEffect } from 'react';

function ThankYouPage({ patientInfo, paymentInfo }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const emailMessage = patientInfo?.emailAddress
        ? `${patientInfo.emailAddress}`
        : 'your email';

    return (
        <div className="text-center">
            <h1 className="pt-5 display-5 fw-bold text-center">Thank You!</h1>
            <div className="col-lg-8 mx-auto">
                <p className="lead mb-4">
                    Your submission was received. You will receive an automated email
                    verification at <strong>{emailMessage}</strong> with important information about your program.
                </p>
            </div>
        </div>
    );
}

export default ThankYouPage;
