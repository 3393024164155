import React, { useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useFormContext } from './InterestFormContext';

function ContactInformation({ nextStep }) {

  const { formData, updateFormData } = useFormContext();
  const [isLoading, setIsLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [ageWarning, setAgeWarning] = useState('');
  const [isUnder13, setIsUnder13] = useState(false); // New state to block submission

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "dateOfBirth") {
      const birthDate = new Date(value);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      const dayDiff = today.getDate() - birthDate.getDate();

      // Adjust age if the birthday hasn't occurred yet this year
      const adjustedAge = monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0) ? age : age - 1;

      if (adjustedAge < 13) {
        setAgeWarning('Be Vital Health does not treat members under 13 years of age.');
        setIsUnder13(true);
      } else {
        setAgeWarning('');
        setIsUnder13(false);
      }
    }

    updateFormData({ [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    // Check if the user is under 13
    if (isUnder13) {
      setValidated(true); // Trigger validation UI
      return; // Stop submission if under 13
    }

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      const firstInvalidInput = form.querySelector(':invalid');
      if (firstInvalidInput) {
        firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    console.log('Form submitted:', formData);
    nextStep(formData.selectedService);
  };

  return (
    <div className="container">
      <div className="row mb-4 align-items-center justify-content-center text-center d-none d-sm-flex">
        <div className="col-12">
          <h3>Are You Ready to Talk to Us, to Learn More, Discuss Options, and Maybe Schedule Your First Appointment?</h3>
        </div>
      </div>

      <div className="row ">
        <div className="col-12">
          <p>Please fill out the form below, to let us know a little more about you, how we might be able to help, and
            we’ll get back to you. We look forward to hearing from you!</p>
        </div>
      </div>

      <form noValidate onSubmit={handleSubmit} className={`row g-3 mt-0 ${validated ? 'was-validated' : ''}`}>

        {/* First Name and Last Name in one row */}
        <div className="row g-3">
          <div className="col-md-6">
            <label htmlFor="firstName" className="form-label">First Name *</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <div className="invalid-feedback">First Name is required</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="lastName" className="form-label">Last Name *</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <div className="invalid-feedback">Last Name is required</div>
          </div>
        </div>

        {/* Email and Phone Number in one row */}
        <div className="row g-3">
          <div className="col-md-6">
            <label htmlFor="emailAddress" className="form-label">Email Address *</label>
            <input
              type="email"
              className="form-control"
              id="emailAddress"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleChange}
              required
            />
            <div className="invalid-feedback">Email Address is required</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="phoneNumber" className="form-label">Phone Number *</label>
            <PatternFormat
              format="(###) ###-####"
              mask="_"
              className="form-control"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
            <div className="invalid-feedback">Phone Number is required</div>
          </div>
        </div>

        {/* Date of Birth and Gender in one row */}
        <div className="row g-3">
          <div className="col-md-6">
            <label htmlFor="dateOfBirth" className="form-label">Date of Birth *</label>
            <input
              type="date"
              className="form-control"
              id="dateOfBirth"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
            <div className="invalid-feedback">Date of Birth is required</div>
            {ageWarning && <div className="text-danger mt-1">{ageWarning}</div>}
          </div>
          <div className="col-md-6">
            <label htmlFor="gender" className="form-label">Gender *</label>
            <select
              id="gender"
              className="form-select"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Choose...</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            <div className="invalid-feedback">Gender is required</div>
          </div>
        </div>

        <div className="col-12">
          <label htmlFor="selectedService" className="form-label">What Services are You Curious About? *</label>
          <select
            className="form-select"
            name="selectedService"
            id="selectedService"
            value={formData.selectedService || ''}
            onChange={handleChange}
            required
          >
            <option value="">Select an Option</option>
            <option value="functionalMedicine">Functional Medicine - Complex, Chronic Illness Diagnostics, Management, and Treatment</option>
            <option value="businessConsulting">Business Meeting / Speaking Engagements for Melanie Dorion</option>
          </select>
          <div className="invalid-feedback">Please select a service type.</div>
        </div>

        <div className="col-12 mt-4 text-center">
          <button type="submit" className="btn btn-primary" disabled={isLoading}>
            {isLoading ? 'Processing ...' : 'Next'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactInformation;
