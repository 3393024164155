import React, { useEffect, useState } from 'react';
import { useEnvironment } from '../components/EnvironmentProvider';
import { useFormContext } from './InterestFormContext';

function FunctionalMedicineForm({ nextStep }) {

    const env = useEnvironment();
    const apiEndpoint = env.apiEndpoint;
    const { formData, updateFormData } = useFormContext();
    const [isLoading, setIsLoading] = useState(true);
    const [validated, setValidated] = useState(false);

    const initialFormData = {
        topChallenges: '',
        whyChange: '',
        severityOptions: '',
        question_4: '',
        question_5: '',
        referralSources: []
    };
    const [currentForm, setCurrentForm] = useState(initialFormData);

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(false);
    }, []);

    const options = {
        severityOptions: [
            { 'id': 'severityOption_1', value: 'Minimal to no Symptoms. I want to Optimize.' },
            { 'id': 'severityOption_2', value: 'Mild to Moderate. I\'m Functioning well Enough.' },
            { 'id': 'severityOption_3', value: 'Moderate to Heavy. I\'m having a Rough Time.' },
            { 'id': 'severityOption_4', value: 'Debilitating and Extensive. Please Help.' }
        ],
        commitmentOptions: [
            { id: 'resistant', value: 'Feeling Very Resistant' },
            { id: 'open', value: 'I\'m Open and Willing, Within Reason' },
            { id: 'committed', value: 'I\'ll do Almost Anything to Feel Better' }
        ],
        illnessExperienceOptions: [
            { id: 'new', value: 'This is all New to Me' },
            { id: 'some', value: 'Some' },
            { id: 'experienced', value: 'Not My First Rodeo' },
            { id: 'long', value: 'A LONG Time' },
            { id: 'na', value: 'Does not Apply' }
        ],
        referralSources: [
            { id: 'practitionerReferral', value: 'Practitioner Referral' },
            { id: 'wrenRadio', value: 'Radio - WREN 97.9' },
            { id: 'wordOfMouth', value: 'Word of mouth / Recommendation' },
            { id: 'cornerRadio', value: 'Radio - The Corner 106.1' },
            { id: 'instagram', value: 'Instagram' },
            { id: 'other', value: 'Other / Don\'t Remember' },
            { id: 'winaRadio', value: 'Radio - 98.9 WINA' },
            { id: 'notSureRadio', value: 'Radio - Not sure where' },
            { id: 'webSearch', value: 'Web Search' },
            { id: 'gainswave', value: 'Radio - GAINSWave/FemiWave' },
            { id: 'flccc', value: 'FLCCC (Front Line COVID-19 Critical Care Alliance) website' }
        ]
    };

    const getOptionValue = (optionsArray, id) => {
        const option = optionsArray.find(opt => opt.id === id);
        return option ? option.value : id;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentForm((prev) => ({ ...prev, [name]: value }));
    };

    const handleOptionChange = (event) => {
        const { value, checked } = event.target;
        setCurrentForm((prev) => ({
            ...prev,
            referralSources: checked
                ? [...prev.referralSources, value]
                : prev.referralSources.filter((opt) => opt !== value),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isQuestion3Valid = currentForm.severityOptions !== '';
        const isReferralSourcesValid = currentForm.referralSources.length > 0;

        if (!e.currentTarget.checkValidity() || !isQuestion3Valid || !isReferralSourcesValid) {
            setValidated(true);
            e.stopPropagation();
            const firstInvalidInput = e.currentTarget.querySelector(':invalid');
            firstInvalidInput?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }

        const submissionData = {
            ...formData,
            topChallenges: currentForm.topChallenges,
            whyChange: currentForm.whyChange,
            severityOfSymptoms: getOptionValue(options.severityOptions, currentForm.severityOptions),
            commitment: getOptionValue(options.commitmentOptions, currentForm.question_4),
            treatmentExperience: getOptionValue(options.illnessExperienceOptions, currentForm.question_5),
            referralSources: currentForm.referralSources.map((id) => getOptionValue(options.referralSources, id))
        };

        updateFormData(submissionData);
        
        try {
            // Prevent the submit button from being pressed
            setIsLoading(true);
            //setErrorMessage(null);

            const response = await fetch(`${apiEndpoint}/information`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(submissionData),
                mode: 'cors',
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                const msg = errorResponse.message || 'Failed to submit the form';
                setIsLoading(false);
                throw new Error(msg);
            }

            updateFormData(submissionData);
            nextStep(4);
         
        } catch (e) {
            //setErrorMessage(e.message);
            setIsLoading(false);
        }




    };

    return (
        <div className='container'>
            <div className='row mb-4 align-items-center justify-content-center text-center d-none d-sm-flex'>
                <div className='col-12'>
                    <h3>Are You Ready to Talk to Us, to Learn More, Discuss Options, and Maybe Schedule Your First Appointment?</h3>
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <p>Please fill out the form below to let us know a little more about you, how we might be able to help, and we’ll get back to you. We look forward to hearing from you!</p>
                </div>
            </div>

            <form noValidate onSubmit={handleSubmit} className={`row g-3 mt-0 ${validated ? 'was-validated' : ''}`}>

                {/* Health Concerns */}
                <div className='col-12 mb-3'>
                    <label htmlFor='topChallenges' className='form-label'>What Are Your Top 3 Challenges or Concerns? For How Long Have They Been Going On? *</label>
                    <textarea
                        className='form-control custom-textarea-height'
                        id='topChallenges'
                        name='topChallenges'
                        value={currentForm.topChallenges}
                        onChange={handleChange}
                        maxLength='10000'
                        required
                    />
                    <div className='form-text'>Max 10000 characters</div>
                    <div className='invalid-feedback'>Please enter your Health Concerns</div>
                </div>

                {/* Why Now */}
                <div className='col-12 mb-3'>
                    <label htmlFor='whyChange' className='form-label'>Why Is Now a Good Time to Make Changes? *</label>
                    <textarea
                        className='form-control custom-textarea-height'
                        id='whyChange'
                        name='whyChange'
                        value={currentForm.whyChange}
                        onChange={handleChange}
                        maxLength='10000'
                        required
                    />
                    <div className='form-text'>Max 10000 characters</div>
                    <div className='invalid-feedback'>Please Enter your Why</div>
                </div>

                {/* Severity of Symptoms */}
                <div className='col-12 mb-3'>
                    <label htmlFor='severityOptions' className='form-label'>How Severe Are Your Current Symptoms? *</label>
                    {options.severityOptions.map((option) => (
                        <div className='form-check' key={option.id}>
                            <input
                                className='form-check-input'
                                type='radio'
                                name='severityOptions'
                                id={option.id}
                                value={option.id}
                                onChange={handleChange}
                                checked={currentForm.severityOptions === option.id}
                                required
                            />
                            <label className='form-check-label' htmlFor={option.id}>
                                {option.value}
                            </label>
                        </div>
                    ))}
                    <div className='invalid-feedback'>Severity of symptoms is required</div>
                </div>

                {/* Making Changes */}
                <div className='col-12 mb-3'>
                    <label htmlFor='question_4' className='form-label'>Making changes can be challenging. How willing are you to commit, make the effort, and go there with us? *</label>
                    <select
                        className='form-select'
                        name='question_4'
                        id='question_4'
                        value={currentForm.question_4 || ''}
                        onChange={handleChange}
                        required
                    >
                        <option value=''>Select an Option</option>
                        <option value='resistant'>Feeling Very Resistant</option>
                        <option value='open'>I'm Open and Willing, Within Reason</option>
                        <option value='committed'>I'll do Almost Anything to Feel Better</option>
                    </select>
                    <div className='invalid-feedback'>Please select a commitment level.</div>
                </div>

                {/* How Long */}
                <div className='col-12 mb-3'>
                    <label htmlFor='question_5' className='form-label'>How long have you been dealing with complex or chronic illness, working with practitioners, and trying to make progress? *</label>
                    <select
                        className='form-select'
                        name='question_5'
                        id='question_5'
                        value={currentForm.question_5 || ''}
                        onChange={handleChange}
                        required
                    >
                        <option value=''>Select an Option</option>
                        <option value='new'>This is all New to Me</option>
                        <option value='some'>Some</option>
                        <option value='experienced'>Not My First Rodeo</option>
                        <option value='long'>A LONG Time</option>
                        <option value='na'>Does not Apply</option>
                    </select>
                    <div className='invalid-feedback'>Please select an option.</div>
                </div>

                {/* Referral Options */}
                <div className='col-12 mb-3'>
                    <label className='form-label'>
                        How did you hear about us? (pick all that apply) *
                    </label>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='practitionerReferral'
                                    name='referralSources'
                                    value='Practitioner Referral'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('Practitioner Referral')}
                                />
                                <label className='form-check-label' htmlFor='practitionerReferral'>Practitioner Referral</label>
                            </div>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='wrenRadio'
                                    name='referralSources'
                                    value='Radio - WREN 97.9'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('Radio - WREN 97.9')}
                                />
                                <label className='form-check-label' htmlFor='wrenRadio'>Radio - WREN 97.9</label>
                            </div>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='wordOfMouth'
                                    name='referralSources'
                                    value='Word of mouth / Recommendation'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('Word of mouth / Recommendation')}
                                />
                                <label className='form-check-label' htmlFor='wordOfMouth'>Word of mouth / Recommendation</label>
                            </div>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='cornerRadio'
                                    name='referralSources'
                                    value='Radio - The Corner 106.1'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('Radio - The Corner 106.1')}
                                />
                                <label className='form-check-label' htmlFor='cornerRadio'>Radio - The Corner 106.1</label>
                            </div>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='instagram'
                                    name='referralSources'
                                    value='Instagram'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('Instagram')}
                                />
                                <label className='form-check-label' htmlFor='instagram'>Instagram</label>
                            </div>
                            {/* Move the Other / Don't Remember option to the bottom of the first column */}
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='other'
                                    name='referralSources'
                                    value='other'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('other')}
                                />
                                <label className='form-check-label' htmlFor='other'>Other / Don't Remember</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='winaRadio'
                                    name='referralSources'
                                    value='Radio - 98.9 WINA'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('Radio - 98.9 WINA')}
                                />
                                <label className='form-check-label' htmlFor='winaRadio'>Radio - 98.9 WINA</label>
                            </div>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='notSureRadio'
                                    name='referralSources'
                                    value='Radio - Not sure where'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('Radio - Not sure where')}
                                />
                                <label className='form-check-label' htmlFor='notSureRadio'>Radio - Not sure where</label>
                            </div>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='webSearch'
                                    name='referralSources'
                                    value='Web Search'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('Web Search')}
                                />
                                <label className='form-check-label' htmlFor='webSearch'>Web Search</label>
                            </div>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='gainswave'
                                    name='referralSources'
                                    value='Radio - GAINSWave/FemiWave'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('Radio - GAINSWave/FemiWave')}
                                />
                                <label className='form-check-label' htmlFor='gainswave'>Radio - GAINSWave/FemiWave</label>
                            </div>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    id='flccc'
                                    name='referralSources'
                                    value='FLCCC (Front Line COVID-19 Critical Care Alliance) website'
                                    className='form-check-input'
                                    onChange={handleOptionChange}
                                    checked={currentForm.referralSources?.includes('FLCCC (Front Line COVID-19 Critical Care Alliance) website')}
                                />
                                <label className='form-check-label' htmlFor='flccc'>FLCCC (Front Line COVID-19 Critical Care Alliance) website</label>
                            </div>
                        </div>
                    </div>
                    {currentForm.referralSources.length === 0 && validated && (
                        <div className='text-danger referral-sources-error'>
                            At least one referral source must be selected.
                        </div>
                    )}
                </div>

                {/* Thanks message section */}
                <div className='row mt-4'>
                    <div className='col-12 text-center'>
                        <h5>Thanks for sharing! Click 'Submit' below and we'll be in touch!</h5>
                        <p>
                            Feel free to also SMS (text) our office at <strong>434-964-6270</strong> after submitting your form.
                            By submitting this form, you agree to us reaching out to you via the contact information provided.
                            We look forward to talking with you!
                        </p>
                    </div>
                </div>

                <div className='col-12 mt-4 text-center'>
                    <button type='submit' className='btn btn-primary' disabled={isLoading}>
                        {isLoading ? 'Processing ...' : 'Submit'}
                    </button>
                </div>

            </form>
        </div>
    );
}

export default FunctionalMedicineForm;