import React, { useEffect } from 'react';
import { useFormContext } from './InterestFormContext';

function ThankYouPage() {

    const { formData } = useFormContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const emailMessage = formData?.emailAddress
        ? `${formData.emailAddress}`
        : 'your email';

    return (
        <div className="container">
            <div className="text-center">
                <h1 className="pt-5 display-5 fw-bold text-center">Thank You!</h1>
                <div className="col-lg-8 mx-auto">
                    <p className="lead mb-4">
                        Your information was received. Thank you for your interest!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ThankYouPage;
