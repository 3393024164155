import React, { useEffect, useState } from 'react';
import { useEnvironment } from '../components/EnvironmentProvider';
import { useFormContext } from './InterestFormContext';
import { useCalendlyEventListener, PopupModal } from "react-calendly";

function BusinessConsultingForm({ nextStep }) {

    const env = useEnvironment();
    const { formData } = useFormContext();
    const apiEndpoint = env.apiEndpoint;
    const discoveryCallCalendarUrl = env.interestFormCalendarUrl;
    const [isOpen, setIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsOpen(true);
    }, []);

    const prefill = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.emailAddress,
        q1: formData.phoneNumber
    };

    const utm = {
        utmSource: 'INTEREST_FORM',
        utmCampaign: formData.idempotencyKey
    };

    useCalendlyEventListener({
        onEventScheduled: async (event) => {
            setIsOpen(false);
            setErrorMessage(null);
            nextStep(4);
        },
    });

    // Example usage with error feedback
    return (
        <div className="container">
            <div className="row mb-4 align-items-center justify-content-center">
                <div className="col-12 col-md-3 text-center">
                    <img src="/bevital_logo_sm.png" alt="Logo" className="img-fluid" />
                </div>
            </div>
            <div className="text-center">
                {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>
                )}
                {/* Calendar Widget */}
                <div>
                    {isOpen && (
                        <PopupModal
                            url={discoveryCallCalendarUrl}
                            prefill={prefill}
                            utm={utm}
                            onModalClose={() => setIsOpen(false)}
                            open={isOpen}
                            rootElement={document.getElementById('root')}
                        />
                    )}
                </div>
            </div>
        </div>
    );

}

export default BusinessConsultingForm;
