import React, { useEffect, useState } from 'react';
import { useEnvironment } from '../components/EnvironmentProvider';
import Contact from '../components/Contact';
import { useCalendlyEventListener, PopupModal } from "react-calendly";

function ContactForm({ formData, setFormData, nextStep }) {

    const env = useEnvironment();
    const applicationId = env.squareAppId;
    const locationId = env.squareLocationId;
    const apiEndpoint = env.apiEndpoint;

    const [isLoading, setIsLoading] = useState(false);
    const [card, setCard] = useState(null);
    const [product, setProduct] = useState(null);  // Initialize as null instead of empty object
    const [paymentOption, setPaymentOption] = useState(null);

    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        let isMounted = true;
        let cardInstance = null;

        const loadSquare = async () => {
            try {
                const payments = window.Square.payments(applicationId, locationId);
                const card = await payments.card();
                cardInstance = card;

                if (isMounted) {
                    await card.attach('#card-container');
                    setCard(card); // Store the card instance in state
                }
            } catch (e) {
                console.error('Square initialization failed', e);
            }
        };

        const initializeForm = () => {
            window.scrollTo(0, 0);
            setIsLoading(true);
            // Find and set the product
            const foundProduct = env.productCatalog?.find(p => p.id === "WE-12543");
            if (foundProduct && foundProduct.billingOptions?.length > 0) {
                setProduct(foundProduct);
                // Set paymentOption to the first billing option
                setPaymentOption(foundProduct.billingOptions[0]);
            } else {
                // Handle the case where the product or billingOptions is unavailable
                setPaymentOption({
                    name: 'Default Billing Option',
                    description: 'No billing option available',
                    totalAmount: 0,
                });
            }
        };

        // Load the Square instance
        loadSquare();

        // Initialize the form and product
        initializeForm();
        setIsLoading(false);

        // Cleanup on unmount or reload
        return () => {
            isMounted = false;
            if (cardInstance) {
                cardInstance.destroy(); // Destroy the card instance
                setCard(null); // Reset card state
            }
        };
    }, [applicationId, locationId, env.productCatalog]); // Combined dependencies

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        // If form validation fails, stop submission
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);

            // Find the first invalid input
            const firstInvalidInput = form.querySelector(':invalid');
            if (firstInvalidInput) {
                firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            return;
        }

        try {
            setIsLoading(true);
            const result = await card.tokenize();
            if (result.status === 'OK') {
                const paymentToken = result.token;
                setErrorMessage(null);
                // Prevent the submit button from being pressed
                setIsLoading(true);

                const submissionData = {
                    memberInfo: {
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        address1: formData.address1,
                        address2: formData.address2,
                        city: formData.city,
                        state: formData.state,
                        zipCode: formData.zipCode,
                        emailAddress: formData.emailAddress,
                        phoneNumber: formData.phoneNumber,
                        dateOfBirth: formData.dateOfBirth,
                        gender: formData.gender,
                    },
                    paymentInfo: {
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        address1: formData.address1,
                        address2: formData.address2,
                        city: formData.city,
                        state: formData.state,
                        zipCode: formData.zipCode,
                        paymentToken: paymentToken
                    },
                    paymentOption: {
                        ...paymentOption,
                    },
                    package: {
                        ...product,
                        verifyTerms: formData.verifyTerms,
                    },
                    idempotencyKey: formData.idempotencyKey
                };

                delete submissionData.package.billingOptions;

                const response = await fetch(`${apiEndpoint}/wellnessevaluation`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(submissionData),
                    mode: 'cors',
                });

                if (!response.ok) {
                    const errorResponse = await response.json();
                    const msg = errorResponse.message || 'Failed to submit the form';
                    throw new Error(msg);
                }

                setErrorMessage(null);
                nextStep();

            } else {
                throw new Error(result.errors ? result.errors[0].message : 'Tokenization failed');
            }
            setIsLoading(false);
        } catch (e) {
            setErrorMessage(e.message);
            setIsLoading(false);
        }
    };


    // Currency Formatter
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <div className="container">
            <div className="row mb-4 align-items-center justify-content-center">
                <div className="col-12 col-md-3 text-center">
                    <img src="/bevital_logo_sm.png" alt="Logo" className="img-fluid" />
                </div>
                <div className="col-12 col-md-5 text-center text-md-start pt-3 pt-md-0">
                    <h1>Wellness Evaluation</h1>
                    <h5>One-on-one Wellness Evaluation with Melanie Dorion</h5>
                </div>
            </div>

            {/* Row 2: Product Description */}
            <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                    <div className="row py-4">
                        <div className="col-12 text-start">
                            <p>Once you fill out this two-part form and hit submit, we will get you set up in our system
                                for your Wellness Evaluation. If you are at an event and need assistance, please see the
                                event assistant to schedule your Wellness Evaluation.</p>
                            <p>If you and your significant other are each registering for your own wellness
                                evaluations, please fill out two forms, one for each of you.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                    <form noValidate onSubmit={handleSubmit}
                        className={`row g-3 mt-0 ${validated ? 'was-validated' : ''}`}>

                        {/* Contact Form */}
                        <Contact formData={formData} handleChange={handleChange} />

                        {/* Selected Package Summary */}
                        {product && paymentOption ? (
                            <div className="card card-body mt-5 bg-light border rounded p-3">
                                <div className="row">
                                    <div className="col-12 col-md-10 text-center text-md-start">
                                        <h5>{product.name}</h5>
                                        <h6 className="text-muted">{paymentOption.name}</h6>
                                        <p>{paymentOption.description}</p>
                                        <p><span className="small text-danger">* Item is not refundable</span></p>
                                    </div>
                                    <div className="col-12 col-md-2 text-md-end text-center text-md-start">
                                        <p className="mb-1"><small>Due Today</small></p>
                                        <h5>{formatter.format(paymentOption.totalAmount)}</h5>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>Loading package details...</div> // Show a loading message until data is ready
                        )}


                        {/* Terms and Conditions */}
                        <div className="col-12 text-start">
                            <h4 className="mt-3 mb-0">Terms and Conditions</h4>
                            <div className="collapse mt-2" id="collapseExample">
                                <div className="card card-body">
                                    <h5 className="my-3">Payment Authorization and Card Storage</h5>
                                    <p>
                                        By entering your payment details, you authorize Be Vital Health, LLC to charge your card for
                                        the agreed
                                        services. Please note that all sales are final and non-refundable. For your convenience and
                                        in
                                        accordance with credit card merchant agreements, Be Vital Health, LLC may store your card
                                        information
                                        securely with our payment processor, Square, Inc. This stored information will be used
                                        solely for future
                                        transactions related to your ongoing care with Be Vital Health, LLC, such as recurring
                                        payments,
                                        additional services, or resolving billing issues.
                                    </p>

                                    <h5 className="my-3">Consent to Communication</h5>
                                    <p>
                                        By providing your contact information, you consent to Be Vital Health, LLC communicating
                                        with you via text
                                        message, email, or phone call for purposes including, but not limited to:
                                    </p>
                                    <dl className="row">
                                        <dt className="col-12 col-md-4">Scheduling:</dt>
                                        <dd className="col-12 col-md-8">Confirming, canceling, or rescheduling appointments.</dd>

                                        <dt className="col-12 col-md-4">Payment Notifications:</dt>
                                        <dd className="col-12 col-md-8">Providing payment reminders, receipts, or notifications of
                                            payment issues.
                                        </dd>

                                        <dt className="col-12 col-md-4">Account Management:</dt>
                                        <dd className="col-12 col-md-8">Managing your account, including updating personal
                                            information or treatment plans.
                                        </dd>

                                        <dt className="col-12 col-md-4">Telemedicine Support:</dt>
                                        <dd className="col-12 col-md-8">Facilitating telemedicine consultations, providing treatment
                                            updates, or following up on care.
                                        </dd>
                                    </dl>
                                    <p>
                                        You may choose your preferred method of communication and update your preferences at any
                                        time by contacting our office.
                                    </p>

                                    <h5 className="my-3">Insurance Limitations</h5>
                                    <p>Be Vital Health Center does not engage in or facilitate any transactions with the health insurance
                                        system. This includes, but is not limited to, the provision of superbills, CPT codes, or any
                                        tax-related documentation for insurance claims or reimbursement purposes.
                                    </p>

                                    <h5 className="my-3">Data Security and Privacy</h5>
                                    <p>
                                        Be Vital Health, LLC is committed to protecting your privacy and the confidentiality of your
                                        personal and payment information. We use Square, Inc. to process and store payment details
                                        securely. All personal data is handled in compliance with applicable privacy laws and
                                        regulations, including HIPAA, to ensure the highest standard of protection.
                                    </p>

                                    <h5 className="my-3">Opt-Out and Revocation of Consent</h5>
                                    <p>
                                        You may revoke your consent to communications at any time by notifying Be Vital Health, LLC
                                        in writing or by using the opt-out mechanisms provided in our communications. Please be
                                        aware that opting out of certain communications may impact our ability to provide you with
                                        timely updates and support related to your care.
                                    </p>

                                    <h5 className="my-3">Legal Agreement</h5>
                                    <p>
                                        By completing and submitting the online form, you acknowledge and agree to the terms
                                        outlined above, constituting a binding legal agreement between you and Be Vital Health, LLC.
                                        Your acceptance is confirmed by submitting your payment information and agreeing to our
                                        terms and conditions.
                                    </p>
                                </div>
                            </div>

                            <div className="form-check mt-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="verifyTerms"
                                    value={formData.verifyTerms}
                                    checked={formData.verifyTerms}
                                    id="verifyTerms"
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="verifyTerms" className="form-check-label">
                                    <span>I affirm that I have read and agree with the </span>
                                    <a
                                        data-bs-toggle="collapse"
                                        href="#collapseExample"
                                        aria-expanded="false"
                                        aria-controls="collapseExample"
                                    >
                                        Terms and Conditions.
                                    </a>
                                </label>
                                <div className="invalid-feedback">
                                    Please confirm you have read the Terms and Conditions
                                </div>
                            </div>
                        </div>

                        {/* Credit Card Form */}
                        <div className="row mt-5">
                            <div className="col-12">
                                <h4>Payment Details</h4>
                            </div>
                            <div id="card-container" className="col-12"></div>
                            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        </div>

                        {/* Privacy Statement */}
                        <div className="card card-body mt-5">
                            <h5 className="my-3">Privacy Statement</h5>
                            <p>
                                At Be Vital, LLC, we prioritize your privacy and security by adhering to strict
                                HIPAA
                                compliance standards. Your personal information is safeguarded through robust
                                encryption
                                technologies, ensuring that your data is protected both in transit and at rest. We
                                are
                                committed to implementing industry-leading security measures to maintain the
                                confidentiality and integrity of your sensitive information.
                            </p>
                        </div>

                        {/* Submit Button */}
                        <div className="col-12 mt-4 text-center">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Processing...' : 'Next'}
                            </button>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    );
}

export default ContactForm;
