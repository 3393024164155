import React, { createContext, useContext, useState, useEffect } from 'react';

const EnvironmentContext = createContext();
export const useEnvironment = () => useContext(EnvironmentContext);

export const EnvironmentProvider = ({ children }) => {
    const apiEndpoint = process.env.REACT_APP_API_URL; // Use REACT_APP_ prefix for environment variables in React
    const [env, setEnv] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        // Define the async function inside useEffect
        const getEnvironment = async () => {
            try {
                const response = await fetch(`${apiEndpoint}/environment`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    mode: 'cors',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                data.apiEndpoint = apiEndpoint;
                setEnv(data);
            } catch (error) {
                console.error('Error fetching environment data:', error);
            } finally {
                setLoading(false);
            }
        };

        getEnvironment();

    }, [apiEndpoint]); // Run useEffect only when apiEndpoint changes

    if (loading) {
        return <div>Loading environment...</div>;
    }

    return (
        <EnvironmentContext.Provider value={env}>
            {children}
        </EnvironmentContext.Provider>
    );
};
