import React, {useEffect} from 'react';

function ThankYouPage({formData}) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const emailMessage = formData?.emailAddress
        ? `${formData.emailAddress}`
        : 'your email';

    return (
        <div className="container">
            <div className="row mb-4 align-items-center justify-content-center">
                <div className="col-12 col-md-3 text-center">
                    <img src="/bevital_logo_sm.png" alt="Logo" className="img-fluid"/>
                </div>
            </div>
            <div className="text-center">
                <h1 className="pt-5 display-5 fw-bold text-center">Thank You!</h1>
                <div className="col-lg-8 mx-auto">
                    <p className="lead mb-4">
                        Your submission was received. You will receive an automated email
                        verification at <strong>{emailMessage}</strong> with confirmation.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ThankYouPage;
