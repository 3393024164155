import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const [paymentOption, setPayment] = useState(null);

    const addToCart = (product) => {
        setCartItems((prevItems) => [...prevItems, product]);
    };

    const emptyCart = () => {
        setCartItems([]);
    };

    const removeFromCart = (productId) => {
        setCartItems((prevItems) => prevItems.filter(item => item.id !== productId));
    };

    const updatePaymentOption = (option) => {
        setPayment(option);
    };

    return (
        <CartContext.Provider value={{
            cartItems,
            addToCart,
            removeFromCart,
            emptyCart,
            paymentOption,         // Include paymentOption in context
            setPaymentOption: updatePaymentOption  // Include setPaymentOption in context
        }}>
            {children}
        </CartContext.Provider>
    );
};
