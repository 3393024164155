import React, { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from "react-router-dom";

const PatientForm = ({ patientInfo, setPatientInfo, handlePrevious, handleNext }) => {

    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPatientInfo(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);

            // Find the first invalid input
            const firstInvalidInput = form.querySelector(':invalid');
            if (firstInvalidInput) {
                firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            return;
        }
        handleNext();
    };


    return (
        <form noValidate onSubmit={handleSubmit} className={`row g-3 mt-0 ${validated ? 'was-validated' : ''}`}>
            <div className="col-sm-12 col-md-6 text-start">
                <label htmlFor="firstName" className="form-label">First Name *</label>
                <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={patientInfo.firstName}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">First Name is required</div>
            </div>
            <div className="col-sm-12 col-md-6 text-start">
                <label htmlFor="lastName" className="form-label">Last Name *</label>
                <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={patientInfo.lastName}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">Last Name is required</div>
            </div>
            <div className="col-12 text-start">
                <label htmlFor="address1" className="form-label">Address *</label>
                <input
                    type="text"
                    className="form-control"
                    id="address1"
                    name="address1"
                    value={patientInfo.address1}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">Address is required</div>
            </div>
            <div className="col-12 text-start">
                <input
                    type="text"
                    className="form-control"
                    id="address2"
                    name="address2"
                    value={patientInfo.address2}
                    onChange={handleChange}
                />
            </div>
            <div className="col-sm-12 col-md-6 text-start">
                <label htmlFor="city" className="form-label">City *</label>
                <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={patientInfo.city}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">City is required</div>
            </div>
            <div className="col-sm-6 col-md-3 text-start">
                <label htmlFor="state" className="form-label">State *</label>
                <select
                    id="state"
                    className="form-select"
                    name="state"
                    value={patientInfo.state}
                    onChange={handleChange}
                    required
                >
                    <option value="">Choose...</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                </select>
                <div className="invalid-feedback">State is required</div>
            </div>
            <div className="col-sm-6 col-md-3 text-start">
                <label htmlFor="zipCode" className="form-label">Zip Code *</label>
                <input
                    type="text"
                    className="form-control"
                    id="zipCode"
                    name="zipCode"
                    value={patientInfo.zipCode}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">Zip Code is required</div>
            </div>
            <div className="col-sm-12 col-md-6 text-start">
                <label htmlFor="emailAddress" className="form-label">Email Address *</label>
                <input
                    type="email"
                    className="form-control"
                    id="emailAddress"
                    name="emailAddress"
                    value={patientInfo.emailAddress}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">Email Address is required</div>
            </div>
            <div className="col-sm-12 col-md-6 text-start">
                <label htmlFor="phoneNumber" className="form-label">Phone Number *</label>
                <PatternFormat
                    format="(###) ###-####"
                    mask="_"
                    className="form-control"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={patientInfo.phoneNumber}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">Phone Number is required</div>
            </div>
            <div className="col-sm-12 col-md-6 text-start">
                <label htmlFor="dateOfBirth" className="form-label">Date of Birth *</label>
                <input
                    type="date"
                    className="form-control"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    value={patientInfo.dateOfBirth}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">Date of Birth is required</div>
            </div>
            <div className="col-sm-12 col-md-6 text-start">
                <label htmlFor="gender" className="form-label">Gender *</label>
                <select
                    id="gender"
                    className="form-select"
                    name="gender"
                    value={patientInfo.gender}
                    onChange={handleChange}
                    required
                >
                    <option value="">Choose...</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                </select>
                <div className="invalid-feedback">Gender is required</div>
            </div>
            <div className="card card-body mt-5">
                <h5 className="my-3">Privacy Statement</h5>
                <p>
                    At Be Vital, LLC, we prioritize your privacy and security by adhering to strict HIPAA compliance
                    standards. Your personal information is safeguarded through robust encryption technologies, ensuring
                    that your data is protected both in transit and at rest. We are committed to implementing
                    industry-leading security measures to maintain the confidentiality and integrity of your sensitive
                    information.
                </p>
            </div>

            {/*/!* Submit Button *!/*/}
            <div className="col-12 mt-4 text-center">
                <button type="button" className="btn btn-secondary me-3" onClick={handlePrevious}>Previous</button>
                <button type="submit" className="btn btn-primary">
                    Next
                </button>
            </div>
        </form>

    );
};

export default PatientForm;