import React, { useState } from 'react';
import ContactForm from './ContactForm';
import ThankYou from './ThankYou';
import {v4 as uuid} from "uuid";

function CheckoutProcess() {

    const idempotencyKey = uuid();
    const [step, setStep] = useState(1);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        emailAddress: '',
        phoneNumber: '',
        dateOfBirth: '',
        gender: '',
        verifyTerms: false,
        idempotencyKey: idempotencyKey
    });

    // Handlers for navigating steps
    const nextStep = () => setStep(step + 1);

    // Render current step
    const renderStep = () => {
        switch (step) {
            case 1:
                return <ContactForm formData={formData} setFormData={setFormData} nextStep={nextStep} />;
            case 2:
                return <ThankYou formData={formData} />;
            default:
                return <ContactForm />;
        }
    };

    return (
        <div className="checkout-process">
            {renderStep()}
        </div>
    );
}

export default CheckoutProcess;
