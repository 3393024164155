import React, { useCallback, useState } from 'react';
import "../css/Registration.css";
import ContactInformation from "./ContactInformation";
import ThankYouPage from "./ThankYou";
import { InterestFormProvider } from './InterestFormContext';
import FunctionalMedicineForm from './FunctionalMedicineForm';
import BusinessConsultingForm from './BusinessConsultingForm';

function Layout() {

    const [step, setStep] = useState(1);

    const previousStep = useCallback(() => {
        setStep(prevStep => Math.max(prevStep - 1, 1));
    }, []);

    const nextStep = useCallback((step) => {
        setStep(step);
    }, []);

    const determineNextStep = useCallback((dropdownValue) => {
        if (dropdownValue === 'functionalMedicine') {
            nextStep(2);
        } else if (dropdownValue === 'businessConsulting') {
            nextStep(3);
        } else {
            console.log("Invalid dropdown selection:", dropdownValue);
        }
    }, [nextStep]);

    return (
        <InterestFormProvider>
            <div className="container justify-content-center">
                {/* Main Content Area */}
                <div id="123" className="col-12 col-md-8 mx-auto">
                    <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                        <div className="row my-4">
                            <div className="col">
                                {step === 1 &&
                                    <ContactInformation nextStep={determineNextStep} />}
                                {step === 2 &&
                                    <FunctionalMedicineForm previousStep={previousStep} nextStep={nextStep} />}
                                {step === 3 && <BusinessConsultingForm previousStep={previousStep} nextStep={nextStep} />}
                                {step === 4 && <ThankYouPage />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </InterestFormProvider>
    );
}

export default Layout;
