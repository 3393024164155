import React, { useEffect, useState } from 'react';
import { useEnvironment } from '../components/EnvironmentProvider';
import Contact from '../components/Contact';
import { useCalendlyEventListener, PopupModal } from "react-calendly";

function ContactForm({ formData, handleChange, nextStep }) {

    const env = useEnvironment();
    const apiEndpoint = env.apiEndpoint;
    const discoveryCallCalendarUrl = env.discoveryCallCalendarUrl;

    const [isLoading, setIsLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(false); // Form initialization completed
    }, []);

    const prefill = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.emailAddress
    };

    const utm = {
        utmSource: 'DISCOVERY_CALL',
        utmCampaign: formData.idempotencyKey
    };

    useCalendlyEventListener({
        onEventScheduled: async (event) => {
            setIsOpen(false);
            setErrorMessage(null);
            nextStep();
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        // If form validation fails, stop submission
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);

            // Find the first invalid input
            const firstInvalidInput = form.querySelector(':invalid');
            if (firstInvalidInput) {
                firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            return;
        }

        try {
            // Prevent the submit button from being pressed
            setIsLoading(true);
            setErrorMessage(null);

            const submissionData = {
                memberInfo: {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    address1: formData.address1,
                    address2: formData.address2,
                    city: formData.city,
                    state: formData.state,
                    zipCode: formData.zipCode,
                    emailAddress: formData.emailAddress,
                    phoneNumber: formData.phoneNumber,
                    dateOfBirth: formData.dateOfBirth,
                    gender: formData.gender,
                    healthConcerns: formData.healthConcerns
                },
                idempotencyKey: formData.idempotencyKey
            };

            const response = await fetch(`${apiEndpoint}/discoverycall`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(submissionData),
                mode: 'cors',
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                const msg = errorResponse.message || 'Failed to submit the form';
                setIsLoading(false);
                throw new Error(msg);
            }

            // Show the Calendly popup modal
            setIsOpen(true);
            return; // Exit the function, don't submit the form
        } catch (e) {
            setErrorMessage(e.message);
            setIsLoading(false);
        }
    };

    return (
        <div className="container">
            <div className="row mb-4 align-items-center justify-content-center">
                <div className="col-12 col-md-3 text-center">
                    <img src="/bevital_logo_sm.png" alt="Logo" className="img-fluid" />
                </div>
                <div className="col-12 col-md-5 text-center text-md-start pt-3 pt-md-0">
                    <h1>Discovery Call</h1>
                    <h5>15 Minute Call with a Be Vital Team Member</h5>
                </div>
            </div>

            {/* Row 2: Product Description */}
            <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                    <div className="row py-4">
                        <div className="col-12 text-start">
                            <p>Once you fill out this two-part form and hit submit, we will get you set up in our system
                                for your Discovery Call.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                    <form noValidate onSubmit={handleSubmit}
                        className={`row g-3 mt-0 ${validated ? 'was-validated' : ''}`}>

                        {/* Contact Form */}
                        <Contact formData={formData} handleChange={handleChange} />

                        {/* Health Concerns */}
                        <div className="row g-3 mt-3">
                            <div className="col-md-12">
                                <label htmlFor="healthConcerns" className="form-label">Health Concerns</label>
                                <textarea
                                    className="form-control"
                                    id="healthConcerns"
                                    name="healthConcerns"
                                    value={formData.healthConcerns}
                                    onChange={handleChange}
                                    maxLength="1500"
                                    required
                                />
                                <div className="form-text">Max 1500 characters</div>
                                <div className="invalid-feedback">Please enter your Health Concerns</div>
                            </div>
                        </div>

                        {/* Calendar Widget */}
                        <div>
                            {isOpen && (
                                <PopupModal
                                    url={discoveryCallCalendarUrl}
                                    prefill={prefill}
                                    utm={utm}
                                    onModalClose={() => setIsOpen(false)}
                                    open={isOpen}
                                    rootElement={document.getElementById('root')}
                                />
                            )}
                        </div>

                        {/* Submit Button */}
                        <div className="col-12 mt-4 text-center">
                            <button type="submit" disabled={isLoading} className="btn btn-primary">
                                Next
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
}

export default ContactForm;
