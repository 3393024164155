import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CartProvider } from './components/CartContext';
import RegistrationLayout from './components/RegistrationLayout';
import BasicRegistrationForm from './package/BasicPackage';
import IntermediateRegistrationForm from './package/IntermediatePackage';
import AdvancedRegistrationForm from './package/AdvancedPackage';
import CheckoutForm from "./package/Checkout";
import ThankYouPage from "./package/ThankYou";
import WellnessEvaluation from "./wellnessEvaluation/WellnessEvaluation";
import DiscoveryCall from "./discoveryCall/DiscoveryCall";
import InterestFormLayout from "./interest/InterestFormLayout";

import { useEnvironment } from './components/EnvironmentProvider';

function App() {
    const [isSquareLoaded, setIsSquareLoaded] = useState(false); // Track loading state
    const env = useEnvironment();
    const squareEnvironment = env.squareEnvironment;

    useEffect(() => {
        const script = document.createElement('script');

        // Set the script URL based on the environment
        script.src = squareEnvironment === 'production'
            ? "https://web.squarecdn.com/v1/square.js"  // Production
            : "https://sandbox.web.squarecdn.com/v1/square.js";  // Sandbox

        script.async = true;

        // Callback when the script is loaded
        script.onload = () => {
            setIsSquareLoaded(true); // Set the loading state to true once the script is loaded
        };

        document.body.appendChild(script);

        return () => {
            // Cleanup: remove the script when the component unmounts
            const existingScript = document.querySelector(`script[src="${script.src}"]`);
            if (existingScript) {
                document.body.removeChild(existingScript);
            }
        };
    }, [squareEnvironment]); // Only run this effect when the environment changes

    // Render a loading spinner or null until Square script is loaded
    if (!isSquareLoaded) {
        return <div>Loading...</div>; // Or render a spinner here
    }

    return (
        <CartProvider>
            <Router>
                <Routes>
                    {/* Nested routes inherit the layout */}
                    <Route path="/package/" element={<RegistrationLayout />}>
                        <Route path="/package/basic/:promoCode?" element={<BasicRegistrationForm />} />
                        <Route path="/package/intermediate/:promoCode?" element={<IntermediateRegistrationForm />} />
                        <Route path="/package/advanced/:promoCode?" element={<AdvancedRegistrationForm />} />
                        <Route path="/package/checkout/:promoCode?" element={<CheckoutForm />} />
                        <Route path="/package/thankYou/:promoCode?" element={<ThankYouPage />} />
                    </Route>
                    <Route path="/wellnessevaluation/" element={<RegistrationLayout />}>
                        <Route path="/wellnessevaluation/" element={<WellnessEvaluation />} />
                    </Route>
                    <Route path="/discoverycall/" element={<RegistrationLayout />}>
                        <Route path="/discoverycall/" element={<DiscoveryCall />} />
                    </Route>

                    <Route path="/interest/" element={<InterestFormLayout />} />
            
                </Routes>
            </Router>
        </CartProvider>
    );
}

export default App;
