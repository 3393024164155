import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../components/CartContext';
import { useEnvironment } from '../components/EnvironmentProvider';
import { Link, useLocation } from 'react-router-dom';

function BasicRegistrationForm() {

    const env = useEnvironment();
    const { addToCart, emptyCart } = useContext(CartContext);
    const [product, setProduct] = useState({});
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);

        emptyCart();

        const theProduct = env.productCatalog.find(product => product.id === "BAS-12432");
        const paymentOptions = theProduct.billingOptions;
        setPaymentOptions(paymentOptions);
        const defaultPaymentOption = paymentOptions[0];

        theProduct.paymentOption = defaultPaymentOption;
        setProduct(theProduct);
        setSelectedPaymentOption(defaultPaymentOption.id);
        addToCart(theProduct);
    }, [pathname, env.productCatalog]);

    const handleOptionChange = (e) => {
        setSelectedPaymentOption(e.target.value);
        emptyCart();

        product.paymentOption = paymentOptions.find(paymentOption => paymentOption.id === e.target.value)
        setProduct(product);
        addToCart(product);
    };

    const handleRowClick = (id) => {
        document.getElementById(id).click();
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const totalFee = product.bundle ? product.bundle.reduce((sum, item) => sum + item.fee, 0) : 0;

    return (
        <div className="container">

            <div className="row mb-4 align-items-center justify-content-center">
                <div className="col-12 col-md-3 text-center">
                    <img src="/bevital_logo_sm.png" alt="Logo" className="img-fluid" />
                </div>
                <div className="col-12 col-md-5 text-center text-md-start pt-3 pt-md-0">
                    <h1>Basic Package Agreement</h1>
                    <h3>with Melanie Dorion</h3>
                </div>
            </div>


            {/* Row 2: Product Description */}
            <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                    <div className="row py-4">
                        <div className="col-12 text-start">
                            <h2>Reclaim Your Health, Your Youth, & Your Life</h2>
                        </div>
                    </div>

                    {/* Product Features and Prices */}
                    {product.bundle && product.bundle.map((item, index) => (
                        <div key={`bundle_${index}`} className="row pb-2 mb-2">
                            <div className="col-8">
                                <h5 className="mb-0 text-start">{item.name}</h5>
                                <p className="mb-0 text-start">{item.description || ''}</p>
                                {item.disclaimers && item.disclaimers.length > 0 && (
                                    <div>
                                        {item.disclaimers.map((disclaimer, disclaimerIndex) => (
                                            <p key={`bundle_disclaimer_${disclaimerIndex}`} className="small text-danger">{disclaimer.description}</p>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="col-4 d-flex justify-content-end">
                                <p className="mb-0">{formatter.format(item.fee)}</p>
                            </div>
                        </div>
                    ))}

                    {/* Summary Row */}
                    <div className="row pt-3 pb-2 mb-2">
                        <div className="col-8">
                            <h5 className="mb-0 text-start">Your Total Investment:</h5>
                            <p className="mb-0 text-start">(If Paid in Full)</p>
                        </div>
                        <div className="col-4 d-flex justify-content-end">
                            <h5>{formatter.format(totalFee)}</h5>
                        </div>
                    </div>

                    {/* Product Disclaimers */}
                    {product.disclaimers && product.disclaimers.map((disclaimer, index) => (
                        <div key={`disclaimer_${index}`} className="row pb-2 mb-2">
                            <div className="col-12">
                                <p><span className="small text-danger">{disclaimer.description}</span></p>
                            </div>
                        </div>
                    ))}

                    {/* Payment Options */}
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                <h4>Payment Options</h4>
                            </div>
                        </div>
                        <form>
                            {paymentOptions.map((option) => (
                                <div
                                    key={option.id}
                                    className="row mb-3 bg-white border rounded p-3 clickable-row"
                                    onClick={() => handleRowClick(option.id)}>
                                    <div className="col-2 col-md-1 d-flex align-items-start pe-0">
                                        <input
                                            type="radio"
                                            id={option.id}
                                            name="options"
                                            value={option.id}
                                            className="form-check-input"
                                            checked={selectedPaymentOption === option.id}
                                            onChange={handleOptionChange}
                                        />
                                    </div>
                                    <div className="col-9 col-md-9">
                                        <h5 className="mb-1 text-start">{product.name} ({option.name})</h5>
                                        <p className="mb-0 text-start">{option.description}</p>
                                        {option.disclaimers && option.disclaimers.length > 0 && (
                                            <div>
                                                {option.disclaimers.map((disclaimer, disclaimerIndex) => (
                                                    <p key={`payment_option_disclaimer_${disclaimerIndex}`} className="small text-danger">{disclaimer.description}</p>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-3 col-md-2 text-end d-none d-md-block">
                                        {option.type === "one-time" ? (
                                            <p className="mb-0">{formatter.format(option.totalAmount)}</p>
                                        ) : (
                                            <p className="mb-0">{formatter.format(option.periodicBillingAmount)}</p>
                                        )}
                                    </div>
                                </div>
                            ))}

                            {/* Centering the Next button */}
                            <div className="d-flex justify-content-center mt-4">
                                <Link to="/package/checkout/{coupon}" className="btn btn-primary btn-lg">Next</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BasicRegistrationForm;
